import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';
import { keep2Decimal } from '@assets/js/regExUtil';

const formatterInspectionProd = type => {
  switch (type) {
    case 0:
      return '前期';
    case 1:
      return '中期';
    case 2:
      return '后期';
  }
};
export const tableProperties = [
  store.state.selection,
  store.state.index,
  {
    label: '验货状态',
    prop: 'mtrb_start',
    itemType: 'select',
    options: [
      { value: 0, label: '正常验货' },
      { value: 1, label: '取消验货' }
    ],
    input: true,
    sortable: false,
    widthAuto: true,
    overflowTooltip: true
  },
  { label: '客户货号', prop: 'cust_catalog_number', itemType: 'input', input: true, sortable: false, widthAuto: true, overflowTooltip: true },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false, widthAuto: true, overflowTooltip: true },
  { label: '采购合同号', prop: 'modr_no', itemType: 'input', input: true, sortable: false, widthAuto: true, overflowTooltip: true },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, widthAuto: true, overflowTooltip: true },
  { label: '验货数量', prop: 'inspecationmtrb_num', itemType: 'input', input: false, sortable: true, widthAuto: true },
  { label: '验货申请人', prop: 'inspecationmtrb_stff_aname', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '验货类型',
    prop: 'inspecationmtrb_type',
    itemType: 'select',
    options: [
      { value: 0, label: '前期' },
      { value: 1, label: '中期' },
      { value: 2, label: '后期' }
    ],
    formatter: val => formatterInspectionProd(val),
    input: true,
    sortable: false,
    widthAuto: true
  },
  {
    label: '验货开始日期',
    prop: 'start_inspecationmtrb_date',
    itemType: 'date',
    input: true,
    sortable: false,
    labelWidth: 160,
    valueFormat: 'timestamp',
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '验货结束日期',
    prop: 'end_inspecationmtrb_date',
    itemType: 'date',
    input: true,
    sortable: false,
    labelWidth: 160,
    valueFormat: 'timestamp',
    formatter: val => getDateNoTime(val, false)
  },
  { label: '验货负责人', prop: 'inspecationmtrb_stff_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { prop: 'stff_name', label: '录入人', itemType: 'select', options: [], input: true, labelWidth: '120px', sortable: false },
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    labelWidth: 160,
    valueFormat: 'timestamp',
    input: true,
    formatter: val => getDateNoTime(val, false)
  },
  store.state.status
];
export const applicationMainFormProperties = [
  {
    label: '验货负责人',
    prop: 'inspecationmtrb_stff_id',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '验货申请人',
    prop: 'inspecationmtrb_stff_aid',
    itemType: 'select',
    size: 'small',
    options: [],
    model: '',
    filterable: true,
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '验货类型',
    prop: 'inspecationmtrb_type',
    itemType: 'select',
    options: [
      { value: 0, label: '前期' },
      { value: 1, label: '中期' },
      { value: 2, label: '后期' }
    ],
    size: 'small',
    model: '',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '验货开始日期',
    prop: 'start_inspecationmtrb_date',
    itemType: 'date',
    size: 'small',
    model: '',
    valueFormat: 'timestamp',
    formatTime: 'yyyy-MM-dd',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '验货结束日期',
    prop: 'end_inspecationmtrb_date',
    itemType: 'date',
    size: 'small',
    model: '',
    valueFormat: 'timestamp',
    formatTime: 'yyyy-MM-dd',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '订单数量',
    prop: 'mtrb_num',
    itemType: 'input',
    size: 'small',
    model: '',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '验货数量',
    prop: 'inspecationmtrb_num',
    itemType: 'input',
    size: 'small',
    model: '',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  }
];

const formatterProdLik = type => {
  switch (type) {
    case 0:
      return '新款';
    case 1:
      return '老款';
    case 2:
      return '修改款';
  }
};
export const applicationEditSubTableProperties = [
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  { label: '采购合同号', prop: 'modr_no', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  {
    label: '要求交期',
    prop: 'modr_pedate',
    itemType: 'date',
    input: false,
    sortable: false,
    labelWidth: 140,
    formatter: val => getDateNoTime(val, false)
  },
  { label: '客户货号', prop: 'cust_catalog_number', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  {
    label: '中文规格',
    prop: 'mtrb_spec',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160,
    subItem: {
      disabled: true,
      type: 'popoverInput',
      maxLength: 255
    }
  },
  {
    label: '产品类型',
    prop: 'prod_ilk',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    formatter: val => formatterProdLik(val)
  },
  { label: '数量单位', prop: 'mtrb_unit', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  { label: '订单数量', prop: 'mtrb_num', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  {
    label: '验货数量',
    prop: 'goods_inspected',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    subItem: {
      required: true,
      type: 'input',
      input: (val, row) => (row.goods_inspected = keep2Decimal(val)),
      maxLength: 10
    }
  },
  { label: 'AQL标准', prop: 'aql_standard', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  { label: '抽检数量', prop: 'sampling_quantity', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  { label: '公司抬头', prop: 'cptt_name', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  {
    label: '备注',
    prop: 'remarks',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 190,
    subItem: {
      disabled: false,
      type: 'popoverInput',
      maxLength: 255
    }
  }
];

import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const applicationMtrb = {
  getApplication: params => getNoCatch(`${topic.applicationMtrbTopic}/getInspecationmtrb`, params),
  deleteApplicationByIds: params => postNoCatch(`${topic.applicationMtrbTopic}/deleteInspecationmtrb`, params),
  addInspecationMtrb: params => postNoCatch(`${topic.applicationMtrbTopic}/addInspecationMtrb`, params),
  editInspecationMtrb: params => postNoCatch(`${topic.applicationMtrbTopic}/editInspecationmtrb`, params),
  getApplicationById: params => getNoCatch(`${topic.applicationMtrbTopic}/getInspecationmtrbById`, params),
  editInspecationMtrbStart: params => getNoCatch(`${topic.applicationMtrbTopic}/editInspecationMtrbStart`, params)
};

<template>
  <div class="vg_wrapper">
    <el-tabs :value="'first'" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <AddHeader @submit="submit()" @cancel="cancel">
          <div slot="otherButton">
            <div class="flexHV vg_mr_8">
              <span>申请单据号:</span>
              <el-input disabled size="small" :value="inspecationmtrb_no" style="width: 160px"></el-input>
            </div>
          </div>
          <div slot="specialStatus" style="display: flex">
            <div class="flexHV">
              <span>状态：</span>
              <el-tag :type="helper.getStatusName(formStatus).type">{{ helper.getStatusName(formStatus).name }}</el-tag>
            </div>
          </div>
        </AddHeader>
        <DynamicForm
          v-bind="$attrs"
          :total-width="mainForm.totalWidth"
          :split="mainForm.split"
          :form-list="mainForm.formProperties"
          ref="dynamicForm"
          class="dynamicForm"
          :is-show="isShow"
          :computed-data="{ mtrb_num: mtrb_num, inspecationmtrb_num: inspecationmtrb_num }"
        />
        <div>
          <el-button :disabled="isShow" class="vg_mb_8" type="danger" size="small" @click="delRow()">删除</el-button>
        </div>
        <DynamicUTable
          ref="dynamicUTable"
          :tableData="subTableData"
          :columns="applicationAddSubTableProperties"
          :need-pagination="false"
          :need-search="false"
          :is-show="isShow"
          @selection-change="val => (subSelections = val)"
        />
        <el-row class="vg_mt_16 vd_bortop">
          <el-col>
            <inputUser :isCookie="true" :stffForm="stffForm" ref="userData"></inputUser>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import AddHeader from '@/views/component/addHeader.vue';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import { applicationEditSubTableProperties, applicationMainFormProperties } from '@/views/InspectionManagement/InspectionApplicationMtrbManage/applicationMtrb';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { applicationMtrb } from '@api/modules/applicationMtrb';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { deleteTableRow, objectArrayReduce } from '@assets/js/arrayUtils';
import InputUser from '@/views/component/inputUser.vue';
import { getDataCenterByPermId, getStff } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ApplicationAdd',
  components: { InputUser, DynamicUTable, DynamicForm, AddHeader },
  data() {
    return {
      isShow: false,
      stffForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(applicationMainFormProperties)
      },
      subTableData: [],
      subSelections: [],
      subTableMap: new Map(),
      applicationAddSubTableProperties: cloneDeep(applicationEditSubTableProperties),
      formStatus: 0,
      inspecationmtrb_no: '',
      inspecationmtrb_id: ''
    };
  },
  computed: {
    mtrb_num() {
      let val = objectArrayReduce(this.subTableData, 'mtrb_num', 2);
      let find = this.mainForm.formProperties.find(({ prop }) => prop === 'mtrb_num');
      if (isNaN(val)) {
        find.model = 0;
        return '自动计算';
      }
      find.model = val;
      return val;
    },
    inspecationmtrb_num() {
      let val = objectArrayReduce(this.subTableData, 'goods_inspected', 2);
      let find = this.mainForm.formProperties.find(({ prop }) => prop === 'inspecationmtrb_num');
      if (isNaN(val)) {
        find.model = 0;
        return '自动计算';
      }
      find.model = val;
      return val;
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      this.rateList = await getDataCenterByPermId({ id: 10046 });
      let syncData = this.$route.params.syncData;
      let syncForm = this.$route.params.syncForm;
      if (syncForm) {
        this.mainForm.formProperties.forEach(item => (item.model = data.form[item.prop]));
      }
      if (syncData) {
        setTimeout(() => {
          this.subTableData = syncData;
          this.subTableData.forEach(sItem => {
            this.rateList.forEach(item => {
              if (sItem.goods_inspected >= Number(item.param1) && sItem.goods_inspected < Number(item.param2)) sItem.sampling_quantity = Number(item.param3);
            });
          });
        });
      }
      await this.changeProperties();
    },
    async changeProperties() {
      let stffId = this.mainForm.formProperties.find(({ prop }) => prop === 'inspecationmtrb_stff_id');
      stffId.options = [...(await getStff('质检')), ...(await getStff('QC'))];
      stffId.model = stffId.options.find(({ label }) => label === '徐新才').value;
      let find = this.mainForm.formProperties.find(({ prop }) => prop === 'inspecationmtrb_stff_aid');
      find.options = await getStff();
      find.model = this.$cookies.get('userInfo').stff_id;
      this.applicationAddSubTableProperties.find(({ prop }) => prop === 'goods_inspected').subItem.change = (val, row, options) => {
        this.rateList.forEach(item => {
          if (val >= Number(item.param1) && val < Number(item.param1)) this.$set(row, 'sampling_quantity', Number(item.param3));
        });
      };
    },
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.$refs.dynamicForm.reset();
          await this.jump(`/inspecationmtrb_list?perm_id=${this.$route.query.perm_id}`);
        })
        .catch(err => console.error(err));
    },
    submit() {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `子表-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      applicationMtrb.addInspecationMtrb(this.getSaveData()).then(({ data }) => {
        this.$message.success('保存成功!');
        this.$refs.dynamicForm.reset();
        this.subTableData = [];
        const props = this.$route.query;
        this.jump('/inspecationmtrb_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: props.perm_id,
              form_id: data.form_id
            })
          )
        });
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.inspecationmtrb_id = this.inspecationmtrb_id;
      formModel.inspecationmtrb_no = this.inspecationmtrb_no;
      formModel.order_type = this.$route.query.order_type;
      formModel.inspecationMtrbList = this.subTableData;
      return formModel;
    },
    delRow() {
      let { tableData, map } = deleteTableRow('inspecation_mtrb_id', this.subTableData, this.subTableMap, this.subSelections);
      this.subTableData = tableData;
      this.subTableMap = map;
    }
  }
};
</script>

<style scoped>
::v-deep .elx-table .elx-body--column.col--ellipsis > .elx-cell {
  max-height: 80px;
}
</style>
